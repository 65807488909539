/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 12, 2024 */
@font-face {
  font-family: aurora_magnollia_serif;
  src: url("aurora-magnollia-serif-bf6577ba84d8604-webfont.e9dcb32e.woff2") format("woff2"), url("aurora-magnollia-serif-bf6577ba84d8604-webfont.842fe7da.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: aurora_magnollia_serif;
  src: url("aurora-magnollia-serif-italic-bf6577ba84d8533-webfont.5ea54f10.woff2") format("woff2"), url("aurora-magnollia-serif-italic-bf6577ba84d8533-webfont.ce709503.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}
/*# sourceMappingURL=landing.9287c36b.css.map */
